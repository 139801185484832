import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { KeyValue } from '@angular/common';

import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { timeout, catchError, map, finalize } from 'rxjs/operators';

import { environment as env } from 'src/environments/environment';

import { NotificationSeverity } from '../../enums';
//import { NotificationService } from '../notification/notification.service';
import { NotifyService } from '../notification/notify.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TokenService } from '../token/token.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';



@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private notification: NotifyService,
    private spinner: NgxSpinnerService,
    private router: Router,
    protected translate: TranslateService
  ) { }

  public post(controller: string, action: string, payload?: any): Observable<any> {
    const endPoint: string = `${env.HttpConfig.EndPoint}/api/${controller}/${action}`;

    const token: string | null = this.token.getLocalToken(env.TokenKeys.AccessToken);
    const lang: string | null = this.token.getLocalToken(env.TokenKeys.LanguageToken);
    const header: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'bearer ' + token,
      'lang': lang ? lang : 'en'
    });


    if (!env.production) {
      console.log('http post endpoint:', endPoint);
      console.log('http post payload:', payload);
    }

    this.spinner.show();
    return this.http.post(endPoint, payload, { headers: header })
      .pipe(
        map((m: any) => {
          if (!env.production) {
            console.log('api return', m);
          }
          return m;
        }),
        timeout(env.HttpConfig.Timeout),
        catchError(this.handleError()),
        finalize(() => this.spinner.hide())
      );
  }

  public postWithFile(controller: string, action: string, payload?: any): Observable<any> {
    const endPoint: string = `${env.HttpConfig.EndPoint}/api/${controller}/${action}`;

    const token: string | null = this.token.getLocalToken(env.TokenKeys.AccessToken);
    const lang: string | null = this.token.getLocalToken(env.TokenKeys.LanguageToken);
    const header: HttpHeaders = new HttpHeaders({
      'Authorization': 'bearer ' + token,
      'lang': lang ? lang : 'en'
    });


    if (!env.production) {
      console.log('http post endpoint:', endPoint);
      console.log('http post payload:', payload);
    }

    this.spinner.show();
    return this.http.post(endPoint, payload, { headers: header })
      .pipe(
        map((m: any) => {
          if (!env.production) {
            console.log('api return', m);
          }
          return m;
        }),
        timeout(env.HttpConfig.Timeout),
        catchError(this.handleError()),
        finalize(() => this.spinner.hide())
      );
  }

  public get(controller: string, action: string, params?: KeyValue<string, any>[]): Observable<any> {
    const queryString: string = params ? params.map(i => {
      return `${encodeURIComponent(i.key)}=${encodeURIComponent(i.value)}`;
    }).join('&') : '';

    const endPoint: string = queryString !== '' ?
      `${env.HttpConfig.EndPoint}/api/${controller}/${action}?${queryString}` :
      `${env.HttpConfig.EndPoint}/api/${controller}/${action}`;

    //const endPoint: string = `${env.HttpConfig.EndPoint}/api/${controller}/${action}`;
    const token: string | null = this.token.getLocalToken(env.TokenKeys.AccessToken);
    const lang: string | null = this.token.getLocalToken(env.TokenKeys.LanguageToken);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      //'Content-Type': 'application/json',
      'Authorization': 'bearer ' + token,
      'lang': lang ? lang : 'en'
    });

    //const options = { headers: httpHeaders, params: httpParams };
    const options = { headers: httpHeaders };

    if (!env.production) {
      console.log('http get endpoint:', endPoint);
      console.log('http get options:', options);
    }

    this.spinner.show();
    return this.http.get(endPoint, options)
      .pipe(
        map((m: any) => {
          if (!env.production) {
            console.log('api return', m);
          }
          return m;
        }),
        timeout(env.HttpConfig.Timeout),
        catchError(this.handleError()),
        finalize(() => this.spinner.hide())
      );
  }

  // public refreshGameToken(controller: string, action: string, params?: KeyValue<string, any>[]): Observable<any> {
  //   const queryString: string = params ? params.map(i => {
  //     return `${encodeURIComponent(i.key)}=${encodeURIComponent(i.value)}`;
  //   }).join('&') : '';

  //   const endPoint: string = queryString !== '' ?
  //     `${env.HttpConfig.RefreshGameTokenEndPoint}/api/${controller}/${action}?${queryString}` :
  //     `${env.HttpConfig.RefreshGameTokenEndPoint}/api/${controller}/${action}`;

  //   //const endPoint: string = `${env.HttpConfig.EndPoint}/api/${controller}/${action}`;
  //   const token: string | null = this.token.getLocalToken(env.TokenKeys.AccessToken);
  //   const lang: string | null = this.token.getLocalToken(env.TokenKeys.LanguageToken);
  //   const httpHeaders: HttpHeaders = new HttpHeaders({
  //     //'Content-Type': 'application/json',
  //     'Authorization': 'bearer ' + token,
  //     'lang': lang ? lang : 'en'
  //   });

  //   //const options = { headers: httpHeaders, params: httpParams };
  //   const options = { headers: httpHeaders };

  //   if (!env.production) {
  //     console.log('http get endpoint:', endPoint);
  //     console.log('http get options:', options);
  //   }

  //   this.spinner.show();
  //   return this.http.get(endPoint, options)
  //     .pipe(
  //       map((m: any) => {
  //         if (!env.production) {
  //           console.log('api return', m);
  //         }
  //         return m;
  //       }),
  //       timeout(env.HttpConfig.Timeout),
  //       catchError(this.handleError()),
  //       finalize(() => this.spinner.hide())
  //     );
  // }

  public auth(username: string, password: string): Observable<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password');

    const httpHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    const endPoint: string = `${env.HttpConfig.EndPoint}/token`;
    const options = { headers: httpHeaders };

    if (!env.production) {
      console.log('http post endpoint:', endPoint);
      console.log('http post body:', body);
    }

    this.spinner.show();
    return this.http.post(endPoint, body, options)
      .pipe(
        map(m => {
          if (!env.production) {
            console.log('api return:', m);
          }
          return m;
        }),
        timeout(env.HttpConfig.Timeout),
        catchError(this.handleError()),
        finalize(() => this.spinner.hide())
      );
  }

  private handleError<T>(result?: T) {
    return (err: any): Observable<T> => {
      if (!env.production) {
        console.log('http error:', err);
      }

      //const message: string = err.error?.error_description ? err.error?.error_description : err.message;
      const code: number = err.error?.Code ? err.error?.Code : err.message;
      const message: string = err.error?.Message ? err.error?.Message : err.message;
      // const uri: string = err.header?.uri ? err.header?.uri : err.message;
      // let stackString: string;

      // if (err instanceof Error) {
      //   stackString = err.stack ? err.stack : err.toString();
      // } else {
      //   stackString = `${'Status: ' + err.status} - ${'Status Text: ' + err.statusText || ''} ${'Error: ' + err.body}`;
      // }

      if (code === 401) {
        this.unAuthorized();
      }

      // if (code === 1) {
      //   console.log("came in")
      //   if (uri.includes("api/mapibank/getuserbanks")) {
      //     //this.router.navigate(["/contentssub/pages/reset-pin"]);
      //     // let title: string = '';
      //     // let message: string = '';

      //     // this.translate.get('notifications.reset_pin.title').subscribe((text: string) => { title = text; });
      //     // this.translate.get('notifications.reset_pin.message').subscribe((text: string) => { message = text; });

      //     // this.notification.displayNotification(title, message, NotificationSeverity.Warn);
      //   }
      // }

      let title: string = '';
      //let message: string = '';
      this.translate.get('notifications.title.error').subscribe((text: string) => { title = text; });
      //this.translate.get('notifications.http_error.message').subscribe((text: string) => { message = text; });

      this.notification.displayNotification(`${title}`, message, NotificationSeverity.Error);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private unAuthorized(): void {
    this.token.removeLocalToken(env.TokenKeys.AccessToken);
    const token: string | null = this.token.getLocalToken(env.TokenKeys.AccessToken);
    this.router.navigate(['/accounts/pages/login']);
  }

  public getBlobData(url: string): Observable<Blob>{
    return this.http.get(url, { responseType: 'blob' }); 
  }
}
