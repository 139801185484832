import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { ScrollerModule } from 'primeng/scroller';
//import { TableModule } from 'primeng/table';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';

const primeModules = [
  CalendarModule,
  DialogModule,
  ToastModule,
  FileUploadModule,
  ScrollerModule,
  ConfirmPopupModule,
  ConfirmDialogModule
  //TableModule
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...primeModules
  ],
  exports: [
    ...primeModules
  ],
  providers: [
    MessageService,
    ConfirmationService
  ]
})
export class PrimengModule { }
