import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  private secretKey: string;

  constructor() {
    this.secretKey = ''; 
  }

  public static SecretKey = {
    Poster: 'p0Ster$Usern@me!',
    Register: 'Reg1^t3rS@cr*&1!'
  };

  setSecretKey(key: string) {
    this.secretKey = key;
  }

  encrypt(plaintext: string): string {
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);
    const iv = new Uint8Array(16);

    const encrypted = CryptoJS.AES.encrypt(plaintext, key, {
        iv: CryptoJS.enc.Hex.parse(CryptoJS.enc.Hex.stringify(CryptoJS.lib.WordArray.create(iv))),
        mode: CryptoJS.mode.CBC,
    });

    return encrypted.toString();
  }

  decrypt(encryptedText: string): string {
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);
    const iv = new Uint8Array(16); // 相当于 C# 中的 new byte[16]

    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, {
        iv: CryptoJS.enc.Hex.parse(CryptoJS.enc.Hex.stringify(CryptoJS.lib.WordArray.create(iv))),
        mode: CryptoJS.mode.CBC,
    });

    return CryptoJS.enc.Utf8.stringify(decrypted);
  }
}