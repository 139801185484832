import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { timeout, catchError, map, finalize } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';

import { TokenService } from '../token/token.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifyService } from '../notification/notify.service';

import { environment as env } from 'src/environments/environment';
import { NotificationSeverity } from 'src/app/enums';
import { of } from 'rxjs/internal/observable/of';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';




@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userLoggedIn = new Subject<boolean>();
  private userName: string;
  constructor(
    private http: HttpClient,
    private token: TokenService,
    private spinner: NgxSpinnerService,
    private notification: NotifyService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.userLoggedIn.next(false);
    this.userName = "";
  }

  public login(username: string, password: string): Observable<boolean> {
    return this.postLogin(username, password)
      .pipe(
        map(t => {
          if (t == undefined) { return false }
          if (t.access_token) {
            this.token.removeLocalToken(env.TokenKeys.AccessToken);
            this.token.setLocalToken(env.TokenKeys.AccessToken, t.access_token);
            this.userLoggedIn.next(true);
            return true;
          } else {
            return false;
          }
        })
      );
  }

  public logout(): Observable<boolean> {
    this.token.removeLocalToken(env.TokenKeys.AccessToken);
    const token: string | null = this.token.getLocalToken(env.TokenKeys.AccessToken);
    this.userLoggedIn.next(false);
    return of(true);
  }

  public isAuthenticated(): boolean {
    const token: string | null = this.token.getLocalToken(env.TokenKeys.AccessToken);
    // Check whether the token is expired and return
    // true or false
    //return !this.jwtHelper.isTokenExpired(token);
    return token ? true : false;
  }

  public getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  private postLogin(username: string, password: string): Observable<any> {
    const body = new HttpParams()
      .set('username', username)
      .set('password', password)
      .set('grant_type', 'password');

    const httpHeaders: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    const endPoint: string = `${env.HttpConfig.EndPoint}/token`;
    const options = { headers: httpHeaders };

    if (!env.production) {
      console.log('http post endpoint:', endPoint);
      console.log('http post body:', body);
    }

    this.spinner.show();
    return this.http.post(endPoint, body, options)
      .pipe(
        map(m => {
          if (!env.production) {
            console.log('api return:', m);
          }
          return m;
        }),
        timeout(env.HttpConfig.Timeout),
        catchError(this.handleError(body)),
        finalize(() => this.spinner.hide())
      );
  }

  private handleError<T>(result?: T) {
    return (err: any): Observable<T> => {
      if (!env.production) {
        console.log('http error:', err);
      }

      const message: string = err.error_description;

      switch (err.error.error) {
        case "invalid_grant":
          //this.notification.displayNotification('Error!', 'Incorrect UID or Password, try add A1 at the back of UID. Example(ABC123+A1)=ABC123A1.', NotificationSeverity.Error);

          let invalid_grant_title: string = '';
          let invalid_grant_message: string = '';
          this.translate.get('notifications.login.invalid_grant.title').subscribe((text: string) => { invalid_grant_title = text; });
          this.translate.get('notifications.login.invalid_grant.message').subscribe((text: string) => { invalid_grant_message = text; });

          this.notification.displayNotification(invalid_grant_title, invalid_grant_message, NotificationSeverity.Error);
          break;
        case "reset_required":
          this.token.removeLocalToken(env.TokenKeys.UsernameToken);
          this.token.setLocalToken(env.TokenKeys.UsernameToken, err.error.error_description);


          let reset_required_title: string = '';
          let reset_required_message: string = '';
          this.translate.get('notifications.login.reset_required.title').subscribe((text: string) => { reset_required_title = text; });
          this.translate.get('notifications.login.reset_required.message').subscribe((text: string) => { reset_required_message = text; });

          this.notification.displayNotification(reset_required_title, reset_required_message, NotificationSeverity.Error);

          this.router.navigate(['/accounts/pages/update-password']);
          break;
        default:
          break;
      }
      return of(result as T);
    };
  }
}
