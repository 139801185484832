import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { NotificationSeverity } from './enums';
import { AuthService, NotifyService, TokenService } from './services';

import { environment as env } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  idleTime = env.IdleTimeOut;
  idleTimer: any = null;
  language: any = null;

  @HostListener('window:keydown')
  @HostListener('document:scroll', ['$event'])
  @HostListener('window:mousemove') resetTimer() {
    this.timeoutAction();
  }
  constructor(
    protected translate: TranslateService,
    private token: TokenService,
    private auth: AuthService,
    private router: Router,
    private notification: NotifyService
  ) {
    this.resetTimer();

    if (token.getLocalToken(env.TokenKeys.LanguageToken) != null) {
      this.language = token.getLocalToken(env.TokenKeys.LanguageToken);
      translate.setDefaultLang(this.language);
    }
    else {
      this.language = translate.getBrowserLang();
      if (this.language) {
        translate.setDefaultLang(this.language);
      }
      else {
        translate.setDefaultLang('en');
      }
    }
  }

  ngOnInit() {
    console.log('AppComponent >> ngOnInit');
    const language = this.token.getLocalToken(env.TokenKeys.LanguageToken);
    if (language) {
      this.translate.use(language);
    }

    window.addEventListener('storage', (event) => {
      if (event.key === 'access_token' && !event.newValue) {
        this.auth.logout().subscribe(() => {
          this.router.navigate(['/accounts/pages/login']);
          let name = this.token.getLocalToken(env.TokenKeys.UserToken);
          let title: string = `The ${name} account has been logged out.`;
          let message: string = '';
          this.notification.displayNotification(title, message, NotificationSeverity.Error);
        });
      }
    });
  }

  private logout(): void {
    let title: string = '';
    let message_1: string = '';
    let message_2: string = '';

    this.translate.get('notifications.session_idle.title').subscribe((text: string) => { title = text; });
    this.translate.get('notifications.session_idle.message_1').subscribe((text: string) => { message_1 = text; });
    this.translate.get('notifications.session_idle.message_2').subscribe((text: string) => { message_2 = text; });

    this.notification.displayNotification(title, message_1 + Math.floor(this.idleTime / 6000) + message_2, NotificationSeverity.Warn);

    // this.notification.displayNotification('You have been logout due to inactivation in ' + Math.floor(this.idleTime / 6000) + ' minutes',
    //   'You have been logout due to inactivation in ' + Math.floor(this.idleTime / 6000) + ' minutes', NotificationSeverity.Warn);

    this.auth.logout().subscribe(resp => {
      if (resp) {
        this.router.navigate(['/accounts/pages/account/login']);
      }
    });
  }

  private timeoutAction(): void {
    if (this.auth.isAuthenticated()) {
      // console.log("Start Idle Timer")
      clearTimeout(this.idleTimer);
      this.idleTimer = setTimeout(() => {
        this.logout();
      }, this.idleTime);
    }
    // This only run the logic while user is login and let the timer clear after logout
    this.auth.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        this.resetTimer();
      } else {
        clearTimeout(this.idleTimer)
      }
    });
  }


}
