import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(private cookie: CookieService) { }

  public getLocalToken(key: string): string | null {
    return localStorage.getItem(key);
  }

  public setLocalToken(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  public removeLocalToken(key: string): void {
    localStorage.removeItem(key);
  }

  public getSessionToken(key: string): string | null {
    return sessionStorage.getItem(key);
  }

  public setSessionToken(key: string, value: any): void {
    sessionStorage.setItem(key, value);
  }

  public removeSessionToken(key: string): void {
    sessionStorage.removeItem(key);
  }

  public getCookieToken(key: string): string | null {
    return this.cookie.get(key);
  }

  public setCookieToken(key: string, value: any): void {
    this.cookie.set(key, value);
  }

  public removeCookieToken(key: string): void {
    this.cookie.delete(key);
  }
}
