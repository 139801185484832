export const environment = {
    env: "development",
    production: false,
    LiveChatUrl: "https://direct.lc.chat/15279354/",
    AndroidApkUrl: "https://www.dev-top1-bo.com/files/topone-",
    IosPdfUrl: "https://www.dev-top1-bo.com/Files/ios_instruction.pdf",
    HtmlLandingUrl: "https://www.dev-top1-bo.com/home/landing",
    BossReferralCode: '4E0574',
    HttpConfig: {
        // EndPoint1: "http://localhost:60410",
        // EndPoint2: "https://c10live.azurewebsites.net",
        // EndPoint: "https://c10qa.azurewebsites.net",
        EndPoint: "https://www.dev-top1-bo.com",
        Timeout: 10000, //10sec
        // RefreshGameTokenEndPoint: "https://c9live-gamerecord-2.azurewebsites.net"
    },
    TokenKeys: {
        UsernameToken: "user_name_token",
        AccessToken: "access_token",
        UserToken: "user_token",
        ThemeModeToken: "theme_mode_token",
        LanguageToken: "language_token",
        RememberMeToken_UN: "remember_me_token_un",
        RememberMeToken_PSD: "remember_me_token_psd",
        TncToken: "tnc_token",
        SelectedGameType: "selected_game_type",
        QrCode: "qr_code",
        ResetPinBackOption: "reset_pin_back_option",
        BankAccBackOption: "bank_acc_back_option",
        RegisterPayload: "register_payload"
    },
    IdleTimeOut: 7200000,
    AppDownloadLink: "https://www.aone33.com/",
    CryptoJsSecretKey: "U2FsdGVkX1+deQlqNboBOm6K0ItlUnVhcjsElF3BNgUyRgNxWLkAY2lpGL8OH3An",
    RegisterLink: "https://www.dev-top1.com/#/accounts/pages/account/register?R="
}
