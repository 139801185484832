import { Injectable } from '@angular/core';

import { Observable, Subject } from 'rxjs';
import { TokenService } from '../token/token.service';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  public _thememode$: Subject<string> = new Subject<string>();
  private _thememode = this._thememode$.asObservable();

  constructor(private token: TokenService) { }

  getTheme(): string {
    return this.token.getLocalToken(env.TokenKeys.ThemeModeToken) ?
      this.token.getLocalToken(env.TokenKeys.ThemeModeToken)! :
      'light';
  }

  setTheme(theme: string) {
    console.log('Theme:', theme);
    this.token.setLocalToken(env.TokenKeys.ThemeModeToken, theme);
    return this._thememode$.next(theme);
  }

  onThemeChange(): Observable<string> {
    return this._thememode;
  }

  public initTheme(theme: string, doc: Document): void {
    if (theme === 'dark') {
      doc.body.classList.remove('light');
      doc.body.classList.add('dark');

      doc.querySelectorAll('.theme-img').forEach(el => {
        if (el.getAttribute("src")?.includes("-light.png")) {
          el.setAttribute("src", el.getAttribute("src")!.replace("-light.png", "-dark.png"));
        }

        if (el.getAttribute("src")?.includes("-light-")) {
          el.setAttribute("src", el.getAttribute("src")!.replace("-light-", "-dark-"));
        }

        // if (el.getAttribute("src")?.includes("dark-mode.png")) {
        //   el.setAttribute("src", el.getAttribute("src")!.replace("dark-mode.png", "light-mode.png"));
        // }


      });
    } else {
      doc.body.classList.remove('dark');
      doc.body.classList.add('light');

      doc.querySelectorAll('.theme-img').forEach(el => {
        if (el.getAttribute("src")?.includes("-dark.png")) {
          el.setAttribute("src", el.getAttribute("src")!.replace("-dark.png", "-light.png"));
        }

        if (el.getAttribute("src")?.includes("-dark-")) {
          el.setAttribute("src", el.getAttribute("src")!.replace("-dark-", "-light-"));
        }

        // if (el.getAttribute("src")?.includes("light-mode.png")) {
        //   el.setAttribute("src", el.getAttribute("src")!.replace("light-mode.png", "dark-mode.png"));
        // }
      });
    }
  }
}
